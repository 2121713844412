import { navigate } from "gatsby"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/news_subscription.json"
import DefaultForm from "../forms/default-form-layout"
import "./assets/styles/_index.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const GreenPropertyCardBadge = props => {
  const [show, setShow] = useState(false)

  const handlePodcast = () => {
    navigate(
      "/resources/latest-news-and-insights?blog-category=podcasts&blog-topic=all-topics"
    )
  }

  const handleModal = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const formTitle = `Get news and insight straight to your inbox.`

  const cta_label = props?.propertyResults
    ? props.green_cta_link?.title
    : props.green_cta_link?.title?props.green_cta_link?.title:props?.cta?.title

  return (
    <div
      className={`green-property-card-badge ${
        props?.isreportpage ? "d-d-fix" : ""
      }`}
    >
      <div className="d-block d-xl-flex justify-content-between align-items-center">
        <div className="badge-text">
          {(props?.green_title || props?.title)&& <h4>{props.green_title?props?.green_title:props?.title}</h4>}
          {(props?.green_content || props?.content) && (
            <ContentModule Content={props.green_content?props.green_content?.data?.green_content:
              props.content?.data?.content
            } />
          )}
        </div>
        <div className="badge-cta">
          {/* <CTALink
            class="button button button-filled-litegreen"
            link={
              props.cta_link?.link
                ? props.cta_link?.link
                : { external_link: props?.cta_link?.custom_link }
            }
            // external_link={props?.cta_link?.custom_link}
            title={props.cta_link?.title}
          /> */}
          {props.propertyResults ? (
            <a
              href="javascript:void(0)"
              onClick={handlePodcast}
              class="button button button-filled-litegreen"
            >
              <i className="icon" />
              {cta_label}
            </a>
          ) : (
            <a
              onClick={handleModal}
              href="javascript:void(0)"
              class="button button button-filled-litegreen"
            >
              <i className="icon" />
              {cta_label}
            </a>
          )}
          {
            <Modal
              show={show}
              // onHide={handleClose}
              backdrop="static"
              centered
              dialogClassName="modal-popup-form news_subscribe"
            >
              <Modal.Body>
                <div className="popup-form-wrapper news_subscribe">
                  <div className="close-modal" onClick={() => handleClose()}>
                    <i className="icon black-cancel-icon"></i>
                  </div>
                  <div>
                    <DefaultForm
                      fields={FormFields}
                      formTitle={formTitle}
                      sourceUrl={pageurl}
                    />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          }
        </div>
      </div>
    </div>
  )
}

export default GreenPropertyCardBadge
