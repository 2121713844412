import { graphql } from "gatsby"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import AreaGuideLanding from "../components/AreaGuideLanding/AreaGuideLanding"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import { formTracking } from "../components/Common/utils"
import LandingStaticCard from "../components/LandingStaticCard/LandingStaticCard"
import MarketReportListing from "../components/MarketReportListing/MarketReportListing"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import Layout from "../components/layout"
import SEO from "../components/seo"


const MarketReportLandingTemplate = props => {
  const data = props.data
  const pageData = data?.strapiPage
  const marketReportData = data?.allStrapiMarketReport?.edges
  const areaData = data?.allStrapiArea?.edges
  const partData = data?.allStrapiQuarter?.edges

  const sortMarketReport = marketReportData?.sort(
    (a, b) => parseInt(a.node.sort) - parseInt(b.node.sort)
  )
  const [shareIcons, setShareIcons] = useState(false)

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }

  let popUpData=""

  if(pageData?.add_page_modules?.length>0){
     const results=pageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <Layout popUpData={popUpData} dark={true} popularSearch={pageData?.select_popular_search?.title}>
      <div className="layout-padding-top"></div>
      
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            choose_menu={pageData?.choose_menu}
            pagename={pageData?.title}
          />
          <div className="share-news-details" onClick={() => openShareicons()}>
            <i className="icon grey-share" />
            <span>Share</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={pageurl}
              />
            )}
          </div>
        </div>
      </Container>

      {pageData?.add_page_modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename === "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" && (
              <AreaGuideLanding pageData={item} />
            )}
          </div>
        )
      })}
      <MarketReportListing
        marketReportData={sortMarketReport}
        strapi_id={pageData?.strapi_id}
        add_page_modules={pageData?.add_page_modules}
        partData={partData}
        areaData={areaData}
      />
      {pageData?.add_page_modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS" && (
              <LandingStaticCard data={item} />
            )}
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
      <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/hh_logo_on_green_background_93feeb7b8b.png"

      />
  )
}


export default MarketReportLandingTemplate

export const query = graphql`
  query ($page_id: String) {
    allStrapiArea(filter: { publish: { eq: true } }) {
      edges {
        node {
          title
          slug
          publish
        }
      }
    }
    allStrapiQuarter(filter: { publish: { eq: true } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          __typename
          id
          description {
            data {
              description
            }
          }
          title
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_COMPONENTS_BADGE_GREEN {
          id
          title
          __typename
          content {
            data {
              content
            }
          }
          cta {
            title
            link {
              slug
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS {
          __typename
          id
          cards {
            title
            content {
              data {
                content
              }
            }
            cta {
              title
              custom_link
              link {
                slug
                strapi_parent {
                  slug
                }
              }
            }
          }
        }
      }
    }

    allStrapiMarketReport(filter: { publish: { eq: true } }) {
      edges {
        node {
          slug
          tail_image {
            url
            alternativeText
          }
          imagetransforms {
            tail_image_Transforms
          }
          publishedAt
          areas {
            title
            slug
          }
          quarter {
            title
            slug
          }
          title
          strapi_id
        }
      }
    }
  }
`
